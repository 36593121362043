import Link from 'next/link';

import initialLettersLogo from '@/assets/img/initial-letters-logo.png';
import { calculateReadingTime } from '@/tools/blog';
import { getPostUrl } from '@lamesarv-sdk/tools';
import { BlogPostField, IBlogPostData } from '@lamesarv-sdk/types';

interface BlogHeroPostProps {
  post: IBlogPostData;
}

export const BlogHeroPost = ({ post }: BlogHeroPostProps) => {
  const categoryName = post[BlogPostField.categories]?.[0]?.name;

  return (
    <Link
      href={getPostUrl(post)}
      className="w-full h-full md:h-auto md:aspect-[1256/667] rounded-2xl overflow-hidden relative font-inter block"
      key={post.slug}
    >
      <img src={post.imageUrl} alt="" className="w-full h-full object-cover" />
      <div className="w-full absolute bottom-0 left-0 h-1/2 bg-gradient-to-t from-black to-transparent" />
      <div className="bottom-12 md:bottom-14 left-6 md:left-12 absolute flex flex-col items-start gap-4 text-white z-10 max-w-2xl">
        {categoryName && (
          <span className="bg-[#4B6BFB] rounded-md px-2.5 py-1 text-sm md:text-base">{categoryName}</span>
        )}
        <h2 className="font-semibold text-2xl md:text-4xl">{post[BlogPostField.title]}</h2>
        <span className="text-base hidden md:block">
          {new Date(post[BlogPostField.publishedAt]).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}{' '}
          | {calculateReadingTime(post[BlogPostField.body])} min read
        </span>
        <div className="flex items-center justify-between md:hidden w-full pr-6">
          <div className="flex items-center gap-3">
            <div className="size-8 flex items-center justify-center rounded-full overflow-hidden p-1.5 bg-[#221e20]">
              <img src={initialLettersLogo.src} className="w-full h-full object-cover" alt="LM Logo" />
            </div>
            <span className="font-inter font-medium text-white">La Mesa RV</span>
          </div>
          <span className="font-inter font-medium text-white">
            {new Date(post[BlogPostField.publishedAt]).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </span>
        </div>
      </div>
    </Link>
  );
};
