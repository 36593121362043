'use client';
import axios from 'axios';
import { useContext, useEffect } from 'react';

import { locationCookie } from '@/constants/settings';
import { LayoutContext } from '@/contexts/LayoutContext';
import { LayoutReducerActionType } from '@/types/layout';
import { geoLocation } from '@lamesarv-sdk/libs';
import { getCookie, setCookie } from '@lamesarv-sdk/tools';

export const GeoLocationHandler = () => {
  const { dispatch } = useContext(LayoutContext);

  const updateGeoLocation = (location: geoLocation.IGeoLocation) => {
    dispatch({
      type: LayoutReducerActionType.updateGeoLocation,
      payload: [location.latitude, location.longitude],
    });
  };

  const getLocationByApi = async () => {
    try {
      const sessionLocation = getCookie(locationCookie);

      if (sessionLocation) {
        updateGeoLocation(JSON.parse(sessionLocation));
        return;
      }

      const { data } = await axios.get<geoLocation.IGeoLocation | { disabled: boolean }>('/api/location');

      if (!(data as { disabled: boolean }).disabled) {
        updateGeoLocation(data as geoLocation.IGeoLocation);
        setCookie(locationCookie, JSON.stringify(data));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: LayoutReducerActionType.updateIsGeoLocationLoading,
        payload: false,
      });
    }
  };

  useEffect(() => {
    if (!navigator.geolocation) {
      getLocationByApi();
      return;
    }

    // This is for 1st time users, if they don't choose any option
    // so, it won't show the loading spinner forever
    const timeout = setTimeout(() => {
      dispatch({
        type: LayoutReducerActionType.updateIsGeoLocationLoading,
        payload: false,
      });
    }, 100);

    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
        if (permissionStatus.state === 'granted') clearTimeout(timeout);
      });
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        clearTimeout(timeout);
        updateGeoLocation(position.coords);
        dispatch({
          type: LayoutReducerActionType.updateIsGeoLocationLoading,
          payload: false,
        });
      },
      () => {
        clearTimeout(timeout);
        getLocationByApi();
      },
    );

    return () => clearTimeout(timeout);
  }, []);

  return null;
};
