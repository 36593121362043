/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { createContext, MutableRefObject, PropsWithChildren, useRef } from 'react';

import { IBasicInventory } from '@lamesarv-sdk/types';

export interface InventoryInfiniteScrollContextData {
  infiniteScrollPageRef: MutableRefObject<number>;
  savedItemsRef: MutableRefObject<IBasicInventory[]>;
  savedMetadataRef: MutableRefObject<any>;
}

export const InventoryInfiniteScrollContext = createContext({} as InventoryInfiniteScrollContextData);

export const InventoryInfiniteScrollProvider = ({ children }: PropsWithChildren) => {
  const savedItemsRef = useRef<IBasicInventory[]>([]);
  const infiniteScrollPageRef = useRef<number>(1);
  const savedMetadataRef = useRef<any>({});

  return (
    <InventoryInfiniteScrollContext.Provider value={{ infiniteScrollPageRef, savedItemsRef, savedMetadataRef }}>
      {children}
    </InventoryInfiniteScrollContext.Provider>
  );
};
