import { isMobile } from '@/tools/views';
import { IBasicInventory, InventoryField } from '@lamesarv-sdk/types';

import { InventoryImageSlider } from '../InventoryImageSlider';
import { IMAGE_HEIGHT, IMAGE_WIDTH } from './';

interface IItemImageProps {
  item: IBasicInventory;
  inventoryImage: string;
}

export const InventoryItemImage = ({ item, inventoryImage }: IItemImageProps) => {
  const hasMultipleImages =
    item[InventoryField.imageCloudinaryIds] && item[InventoryField.imageCloudinaryIds].length > 1;

  if (!hasMultipleImages)
    return (
      <img src={inventoryImage} alt={item[InventoryField.title]} className="w-full object-cover inventory-item-image" />
    );

  return (
    <InventoryImageSlider
      height={IMAGE_HEIGHT}
      width={IMAGE_WIDTH}
      images={item[InventoryField.imageCloudinaryIds].slice(0, 5)}
      className="w-full inventory-item-image"
      swipe={isMobile()}
      dots
    />
  );
};
