'use client';

import Slider from 'react-slick';
import { twMerge } from 'tailwind-merge';

import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBlogPostData } from '@lamesarv-sdk/types';

import { BlogHeroPost } from './BlogHeroPost';

interface CustomArrowProps {
  onClick?: () => void;
  className?: string;
  isRight?: boolean;
}

const CustomArrow = ({ className, onClick, isRight }: CustomArrowProps) => (
  <button
    type="button"
    onClick={onClick}
    className={twMerge(
      'bg-[#040404] bg-opacity-50 backdrop-blur-sm size-[60px] rounded-full absolute top-1/2 -translate-y-1/2 md:flex items-center justify-center cursor-pointer z-10 hover:bg-opacity-75 hidden',
      isRight ? 'right-8' : 'left-8',
      className,
    )}
  >
    <FontAwesomeIcon className="text-white size-8" icon={isRight ? faChevronRight : faChevronLeft} />
  </button>
);

const CustomPaging = () => (
  <figure className="w-[50px] h-[6px] rounded-full bg-white/50 cursor-pointer hover:bg-white/75" />
);

interface BlogHeroSliderProps {
  blogPosts: IBlogPostData[];
}

export const BlogHeroSlider = ({ blogPosts }: BlogHeroSliderProps) => (
  <Slider
    slidesToScroll={1}
    slidesToShow={1}
    className="rounded-2xl overflow-hidden aspect-[1256/667] flex-1 [&_.slick-list]:h-full [&_.slick-track]:h-full mb-1.5 shadow-lg font-inter"
    arrows
    dots
    dotsClass="absolute bottom-5 md:bottom-14 right-1/2 md:right-44 !flex gap-2.5 [&>li.slick-active_figure]:!bg-white translate-x-1/2 md:translate-x-0"
    customPaging={CustomPaging}
    autoplay
    nextArrow={<CustomArrow isRight />}
    prevArrow={<CustomArrow />}
  >
    {blogPosts.map((post) => (
      <BlogHeroPost key={post.slug} post={post} />
    ))}
  </Slider>
);
