'use client';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef } from 'react';

import { useCustomSearchParams } from '@lamesarv-sdk/hooks';
import { filtersTypeMap, InventoryFieldMap, ItemsReducerActionType } from '@lamesarv-sdk/types';

import { InventoryContext } from './InventoryContext';

interface IInventoryFiltersContext {
  filtersToHide: Set<string>;
  callbackClearFilters: () => void;
  callbackApplyFilter: (facetKey: string, newFacetValue: string, remove?: boolean) => Promise<void>;
}

export const InventoryFiltersContext = createContext({} as IInventoryFiltersContext);

let skipSearchParamUpdate = false;

interface InventoryFiltersProviderProps {
  filtersToHide?: Set<string>;
}

export const InventoryFiltersProvider = ({
  filtersToHide,
  children,
}: PropsWithChildren<InventoryFiltersProviderProps>) => {
  const { context, clearFilters, setFilter, filtersToUrl, dispatch, getFiltersCurrent } = useContext(InventoryContext);

  const { setRawParams, searchParams } = useCustomSearchParams();
  const newSearchParamsRef = useRef(new URLSearchParams(searchParams.toString()));
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (skipSearchParamUpdate) {
      skipSearchParamUpdate = false;
      return;
    }

    newSearchParamsRef.current = new URLSearchParams(searchParams.toString());

    if (!context.inventoryFiltersBase) return;
    getFiltersCurrent(searchParams, false, context.inventoryFiltersBase);
  }, [searchParams]);

  const clearQuery = () => {
    newSearchParamsRef.current.delete('q');
    dispatch({
      type: ItemsReducerActionType.updateQuery,
      payload: '',
    });
  };

  const resetPage = () => {
    newSearchParamsRef.current.delete('page');
    dispatch({
      type: ItemsReducerActionType.updateMetadata,
      payload: {
        ...context.inventoryMetadata,
        currentPage: 1,
      },
    });
  };

  const applySearchParams = () => {
    setRawParams(newSearchParamsRef.current.toString());
  };

  const filtersKeys = useMemo(() => {
    return new Set(
      [...Object.keys(context.inventoryFiltersBase || {}), ...Object.keys(filtersTypeMap)]
        .map((key) => InventoryFieldMap.get(key))
        .filter(Boolean) as string[],
    );
  }, [context.inventoryFiltersBase]);

  const callbackClearFilters = () => {
    skipSearchParamUpdate = true;

    filtersKeys.forEach((key) => {
      newSearchParamsRef.current.delete(key);
    });
    clearFilters();
    clearQuery();
    resetPage();
    applySearchParams();
  };

  const callbackApplyFilter = async (facetKey: string, newFacetValue: string, remove?: boolean) => {
    skipSearchParamUpdate = true;

    if (facetKey === 'q') {
      clearQuery();
    } else {
      const inventoryFilters = setFilter(facetKey, newFacetValue, remove);
      const urlFilters = filtersToUrl(inventoryFilters);

      newSearchParamsRef.current = new URLSearchParams(urlFilters);

      Array.from(searchParams.entries()).forEach(([key, value]) => {
        if (filtersKeys.has(key)) return;

        newSearchParamsRef.current.set(key, value);
      });
    }

    resetPage();
    applySearchParams();
  };

  return (
    <InventoryFiltersContext.Provider
      value={{ filtersToHide: filtersToHide || new Set(), callbackApplyFilter, callbackClearFilters }}
    >
      {children}
    </InventoryFiltersContext.Provider>
  );
};
