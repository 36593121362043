/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';

import { useSearchParams } from 'next/navigation';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { twMerge } from 'tailwind-merge';

import { FormContactInfo } from '@/components/Form/FormContactInfo/FormContactInfo';
import { InventorySearchPreFilter } from '@/components/Inventory/InventoryPreFilter';
import { defaultImageUrl } from '@/constants/inventory';
import { InventoryContext } from '@/contexts/InventoryContext';
import { InventoryInfiniteScrollContext } from '@/contexts/InventoryInfiniteScrollContext';
import { LayoutContext } from '@/contexts/LayoutContext';
import { withSuspense } from '@/tools/withSuspense';
import { FormType } from '@/types/forms';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, LaMesaRecVanLogo, Pagination, Subtitle } from '@lamesarv-sdk/components';
import { SearchParamAction, useCustomSearchParams } from '@lamesarv-sdk/hooks';
import { GridInventory } from '@lamesarv-sdk/inventory';
import { convertFilters } from '@lamesarv-sdk/tools';
import {
  IBasicInventory,
  IComponentSearchResults,
  IMetaData,
  InventoryField,
  ItemsReducerActionType,
  PropsWithClasses,
} from '@lamesarv-sdk/types';

import { CurrentFiltersWithClear } from '../Inventory/InventoryFilters';
import { inventoryGridClassName } from '../Inventory/InventoryGrid';
import { InventoryItem } from '../Inventory/InventoryItem';
import { InventoryMobileFilters } from '../Inventory/InventoryMobileFilters';

interface ISearchItemsProps {
  items: IBasicInventory[];
  metadata: IMetaData;
}

const SearchItems = (props: ISearchItemsProps) => {
  const items = props.items.map((item, index) => {
    return <InventoryItem key={index} item={item} />;
  });

  return (
    <div className="mt-8 mb-8">
      <Grid
        items={items}
        baseCols={1}
        smCols={1}
        mdCols={1}
        lgCols={2}
        xlCols={3}
        gap={6}
        className={inventoryGridClassName}
      />
    </div>
  );
};

const RefineSortBy = (props: PropsWithClasses) => {
  const { context } = useContext(InventoryContext);
  const { updateSearchParams } = useCustomSearchParams();

  const items = [
    { label: 'Recommended', value: `${InventoryField.order}:asc` },
    { label: 'Most Recent', value: `${InventoryField.modelYear}:desc` },
    { label: 'Oldest', value: `${InventoryField.modelYear}:asc` },
    { label: 'Price Low to High', value: `${InventoryField.price}:asc` },
    { label: 'Price High to Low', value: `${InventoryField.price}:desc` },
  ];

  const handleChange = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value;

    updateSearchParams([
      {
        action: SearchParamAction.remove,
        key: 'page',
      },
      {
        action: SearchParamAction.add,
        key: 'sortBy',
        value: String(value),
      },
    ]);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const sortBy = useMemo(() => {
    return context.inventoryMetadata?.sortBy || InventoryField.order;
  }, [context.inventoryMetadata]);

  return (
    <select
      className={twMerge('border border-gray-100', props.className)}
      value={sortBy}
      onChange={handleChange}
      data-testid="select-sort-by"
    >
      {items.map((option, index) => {
        return (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </select>
  );
};

interface IRefinePerPageProps extends PropsWithClasses {
  defaultValue: number;
}

const RefinePerPage = (props: IRefinePerPageProps) => {
  const { context } = useContext(InventoryContext);
  const { updateSearchParams } = useCustomSearchParams();

  const items = [
    { label: '6 items', value: 6 },
    { label: '12 items', value: 12 },
    { label: '24 items', value: 24 },
    { label: '48 items', value: 48 },
  ];

  const handleChange = (e: SyntheticEvent) => {
    const value = parseInt((e.target as HTMLInputElement).value, 10);

    updateSearchParams([
      {
        action: SearchParamAction.remove,
        key: 'page',
      },
      {
        action: SearchParamAction.add,
        key: 'perPage',
        value: String(value),
      },
    ]);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const perPage = useMemo(() => {
    return context.inventoryMetadata?.perPage || props.defaultValue;
  }, [context.inventoryMetadata]);

  return (
    <select
      className={`border border-gray-100 ${props.className ?? ''}`}
      value={perPage}
      onChange={handleChange}
      data-testid="select-per-page"
    >
      {items.map((item, index) => {
        return (
          <option key={index} value={String(item.value)}>
            {item.label}
          </option>
        );
      })}
    </select>
  );
};

interface IRefinePaginationProps {
  radix: number;
  nbHits: number;
  nbPages: number;
  currentPage?: number;
  setCurrentPage?: Dispatch<SetStateAction<number>>;
}

const RefinePagination = (props: IRefinePaginationProps) => {
  const { context, dispatch } = useContext(InventoryContext);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { setSearchParam } = useCustomSearchParams();

  const perPage = context.inventoryMetadata?.perPage ? Number(context.inventoryMetadata?.perPage) : 12;

  const callback = (page: number) => {
    setSearchParam('page', String(page + 1));
    dispatch({
      type: ItemsReducerActionType.updateMetadata,
      payload: {
        ...context.inventoryMetadata,
        currentPage: page + 1,
      },
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (context.inventoryMetadata?.currentPage === undefined) return;
    setCurrentPage(Number(context.inventoryMetadata?.currentPage) - 1);
  }, [context.inventoryMetadata?.currentPage]);

  const classesBase =
    'flex flex-row items-center py-1 px-2.5 text-sm border border-gray-100 cursor-pointer uppercase text-sage-700';
  const classesNormal = 'bg-gradient-to-b from-white to-gray-50 hover:from-white hover:to-gray-100';
  const classesDisabled = 'bg-gradient-to-b from-white to-gray-50 cursor-not-allowed';
  const classesSelected = 'bg-gradient-to-b from-gray-100 to-white hover:from-gray-100 hover:to-white';

  return (
    <Pagination
      totalItems={props.nbHits}
      pageSize={perPage}
      radix={props.radix}
      callback={callback}
      currentPage={currentPage}
      classesBase={classesBase}
      classesNormal={classesNormal}
      classesDisabled={classesDisabled}
      classesSelected={classesSelected}
    />
  );
};

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      type="button"
      className={`fixed bottom-5 right-5 bg-neutral-900 text-white p-2.5 pb-1.5 rounded-md ${isVisible ? 'block' : 'hidden'} md:hidden`}
      onClick={scrollToTop}
    >
      <FontAwesomeIcon icon={faChevronUp} className="w-5 h-5" />
    </button>
  );
};

interface ISearchResultsTitleProps {
  defaultTitle?: string;
}

const SearchResultsTitle = ({ defaultTitle }: ISearchResultsTitleProps) => {
  const searchParams = useSearchParams();

  return (
    <h1 className="text-2xl xl:text-3xl text-sage-800 font-bold mb-5 uppercase text-center md:text-left">
      {searchParams.get('title') || defaultTitle || "La Mesa RV's Inventory"}
    </h1>
  );
};

interface ISearchResultsProps extends IComponentSearchResults {
  metadata: IMetaData;
}

export const SearchResults = withSuspense((props: ISearchResultsProps) => {
  const { context: layoutContext } = useContext(LayoutContext);
  const { context, getInventoryItems } = useContext(InventoryContext);

  const { infiniteScrollPageRef, savedItemsRef, savedMetadataRef } = useContext(InventoryInfiniteScrollContext);

  const [items, setItems] = useState<IBasicInventory[]>(savedItemsRef.current);
  const [itemsMetadata, setItemsMetadata] = useState<any>(savedMetadataRef.current);
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [ref, inView] = useInView({
    rootMargin: '0px 0px 50% 0px',
  });

  const firstFetch = useRef(true);

  const inventoryFilters = useMemo(() => {
    let inventoryFilters = {};

    if (context?.inventoryFilters && !(Object.keys(context?.inventoryFilters).length === 0)) {
      inventoryFilters = context.inventoryFilters;
    } else if (props?.filters) {
      inventoryFilters = InventorySearchPreFilter(convertFilters(props.filters));
    }

    return inventoryFilters;
  }, [context?.inventoryFilters, props.filters]);

  const searchLimit = props?.limit ? props?.limit : 12;

  useEffect(() => {
    if (
      context.inventoryFilters === undefined ||
      context.inventoryQuery === undefined ||
      context.inventoryMetadata === undefined ||
      layoutContext.isGeoLocationLoading ||
      props?.forceLoading
    )
      return;

    if (firstFetch.current) {
      firstFetch.current = false;

      if (savedItemsRef.current.length > 0) return;
    }

    const getItems = async () => {
      setLoading(true);
      const itemsResult = await getInventoryItems(
        inventoryFilters,
        context.inventoryQuery,
        context.inventoryMetadata?.perPage,
        context.inventoryMetadata?.currentPage,
        context.inventoryMetadata?.sortBy,
        undefined,
        undefined,
      );

      const newItems = (itemsResult as any).items as IBasicInventory[];

      infiniteScrollPageRef.current = 1;
      savedItemsRef.current = newItems;
      savedMetadataRef.current = (itemsResult as any).metadata;
      setItems(newItems);
      setItemsMetadata((itemsResult as any).metadata);
      setLoading(false);
    };

    getItems();
  }, [
    inventoryFilters,
    context.inventoryQuery,
    context.inventoryMetadata?.perPage,
    context.inventoryMetadata?.currentPage,
    context.inventoryMetadata?.sortBy,
    layoutContext.isGeoLocationLoading,
    props?.forceLoading,
  ]);

  const getMoreItems = async () => {
    infiniteScrollPageRef.current++;

    const itemsResult = await getInventoryItems(
      inventoryFilters,
      context.inventoryQuery,
      context.inventoryMetadata?.perPage,
      infiniteScrollPageRef.current,
      context.inventoryMetadata?.sortBy,
    );

    const newItems = (itemsResult as any).items as IBasicInventory[];
    const newMetadata = (itemsResult as any).metadata;

    setItems((prevItems) => {
      const finalItems = [...prevItems, ...newItems];

      savedItemsRef.current = finalItems;

      return finalItems;
    });

    savedMetadataRef.current = newMetadata;
    setItemsMetadata(newMetadata);
  };

  useEffect(() => {
    if (!inView) return;

    getMoreItems();
  }, [inView]);

  return (
    <div className={twMerge('relative', props.className)}>
      {!props.hideTitle && <SearchResultsTitle defaultTitle={props.defaultTitle} />}
      {props.displayPagination && (
        <Fragment>
          <div className="md:flex flex-col gap-2 xl:flex-row mb-3 items-start hidden">
            <div className="w-full flex-grow mb-3 md:mb-0 md:w-auto hidden md:block">
              <RefinePagination radix={2} nbHits={itemsMetadata.nbHits} nbPages={itemsMetadata.nbPages} />
            </div>
            <div className="flex flex-col flex-shrink w-full md:w-auto md:flex-row">
              <div className="flex-shrink mb-3 md:mb-0 md:mr-1 hidden md:block">
                <RefinePerPage defaultValue={12} className="w-full md:w-auto uppercase text-xs text-sage-800" />
              </div>
              <div className="flex-shrink block">
                <RefineSortBy className="w-full md:w-auto uppercase text-xs text-sage-800" />
              </div>
            </div>
          </div>
          <div className="mt-4 md:hidden">
            <InventoryMobileFilters metadata={props.metadata} showSimpleViewButton />
          </div>
        </Fragment>
      )}
      {((!!context.inventoryFilters && Object.keys(context.inventoryFilters).length > 0) ||
        !!context.inventoryQuery) && (
        <div className="md:hidden my-3">
          <CurrentFiltersWithClear />
        </div>
      )}
      {!!items?.length && (
        <Fragment>
          <SearchItems items={items as IBasicInventory[]} metadata={props.metadata} />
          {!(loading || props.forceLoading) && itemsMetadata.nbHits > items.length && (
            <div ref={ref} className="flex flex-row justify-center mt-10 animate-pulse md:hidden">
              <LaMesaRecVanLogo className="h-20" />
            </div>
          )}
        </Fragment>
      )}
      {loading === false && !items?.length && (
        <div className="">
          <Subtitle content="No Search Results found." className="normal-case font-medium text-lg" />
          <FormContactInfo formType={FormType.contactInfo} title="Tell us what are you looking for" className="mb-2" />
          <h2 className="mt-7 mb-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">
            Below are some units you might be interested in.
          </h2>
          <GridInventory
            filters={`${InventoryField.special}:=true`}
            queryConditional={context?.inventoryQuery ?? ''}
            baseCols={1}
            smCols={2}
            mdCols={2}
            lgCols={3}
            xlCols={3}
            gap={6}
            limit={searchLimit}
            metadata={props.metadata}
            defaultImageUrl={defaultImageUrl}
            CustomInventoryItem={(item: IBasicInventory) => <InventoryItem item={item} />}
            className={inventoryGridClassName}
          />
        </div>
      )}
      {props.displayPagination && (
        <div className="md:flex flex-col gap-2 xl:flex-row mb-3 items-start hidden">
          <div className="w-full flex-grow mb-3 md:mb-0 md:w-auto">
            <RefinePagination radix={2} nbHits={itemsMetadata.nbHits} nbPages={itemsMetadata.nbPages} />
          </div>
          <div className="flex flex-col flex-shrink w-full md:w-auto md:flex-row">
            <div className="flex-shrink mb-3 md:mb-0 md:mr-1 ">
              <RefinePerPage defaultValue={12} className="w-full md:w-auto uppercase text-xs text-sage-800" />
            </div>
            <div className="flex-shrink block">
              <RefineSortBy className="w-full md:w-auto uppercase text-xs text-sage-800" />
            </div>
          </div>
        </div>
      )}
      {loading ||
        (props.forceLoading && (
          <div className="absolute bg-white opacity-70 inset-0 py-96 text-center">
            <LaMesaRecVanLogo className="inline-block m-x-auto w-48" />
          </div>
        ))}
      <ScrollToTop />
    </div>
  );
});
