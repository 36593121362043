'use client';

import { createContext, PropsWithChildren, useMemo } from 'react';

import { ClientEnv } from '@/env';

export interface EnvContextData {
  env: ClientEnv;
}

export const EnvContext = createContext({} as EnvContextData);

interface EnvProviderProps {
  clientEnv: ClientEnv;
}

export const EnvProvider = ({ clientEnv, children }: PropsWithChildren<EnvProviderProps>) => {
  const contextData = useMemo((): EnvContextData => ({ env: clientEnv }), [clientEnv]);

  return <EnvContext.Provider value={contextData}>{children}</EnvContext.Provider>;
};
